<script>
import { onMounted, watch, onUnmounted, ref, reactive, inject } from 'vue'
import QRCode from 'qrcodejs2'
import { useRoute, useRouter } from 'vue-router'
import { upload } from 'qiniu-js'
import qiniuUpload from '@/utils/qiniu-upload'
import { http } from '@/http'
import { Toast } from 'vant'
import wx from '@/utils/weixin'
import BigNumber from 'bignumber.js'
import { uuid, dataURLtoFile, regFenToYuan } from '@/utils'
import { needLoading } from '@/reactive'

export default {
  setup() {
    let orderInfo = reactive({})
    let qrcodeRef = ref(null)
    let qrcode = null
    let route = useRoute()
    let router =useRouter()
    let params = JSON.parse(window.sessionStorage.getItem(route.query.id))
    let time = ref(Date.parse(params.payTimeout) - Date.now())
    let interval = ref(null)

    function makeQRCode() {
      if(params) {
        needLoading.value = true
        return http.post('/?cmd=com.xqxc.api.pigear.pay.PayService.createPay', {
          accessType: params.accessType,
          channelType: params.channelType,
          device: 'H5',
          orderId: Number(params.orderId),
          orderType: params.orderType,
          sourceType: 'H5'
        }).then((res) => {
          if (res.result) {
            for(let item in res.result) {
              orderInfo[item] = res.result[item]
            }
          }
        })
      }
      else {
        // todo
      }
      
    }

    function getOrderPayResult(data) {
      http.post('/?cmd=com.xqxc.api.pigear.pay.PayService.getOrderPayResult', {
        ...data
      }).then(res => {

        if(res.result && res.result.payStatus === 'SUCCESS') {
          router.push(`/pay/result?totalFee=${res.result.totalFee}`)
        }
      })
    }

    watch(orderInfo, (a, b) => {
      interval.value = window.setInterval(() => {
        getOrderPayResult({
          payGroupId: a.payGroupId,
          payId: a.payId,
          orderType: a.orderType,
        })
      }, 2000)
    })
    
    onUnmounted(() => {
      clearInterval(interval.value)
      interval.value = null
    })
    
    onMounted(() => {
      qrcode = document.getElementById('qrcode')
      makeQRCode().then(() => {
        try {
          qrcode.innerHTML = ''
          qrcodeRef.value = new QRCode(qrcode, {
            text: orderInfo.payParam.gateway_url,
            width: 180,
            height:180
          })        

          let canvas = document.querySelectorAll('canvas')

          if(canvas[0]) {
            let files = dataURLtoFile(canvas[0].toDataURL(), uuid())
            qiniuUpload(files, {
              errorCallback() {
              },
              completeCallback(res) {
                let img = document.createElement('img')
                img.setAttribute('src', res.result) 
                qrcode.appendChild(img)
                // 搞不懂微信的规则。。。
                qrcode.style.visibility = 'visible'
                if(document.querySelectorAll('img')[1]){
                  document.querySelectorAll('img')[1].crossOrigin = 'anonymous'
                  document.querySelectorAll('img')[1].style.cssText = 'z-index:1000; opacity:1'
                }
                
              }
            })
          }
        } catch(e) {
          // console.log(e)
        }
      
      })
    })

    return {
      time,
      params,
      orderInfo,
      BigNumber
    }
  }
}
</script>
<template>
  <div class="page">
    
    <div class="r-box" style="margin-top:13px">
      <div>
        <h4>¥{{new BigNumber(params.payPrice).toFixed(2)}}</h4>
        <p style="margin-bottom:.3rem">已减免{{new BigNumber(params.discount).toFixed(2)}}元</p>
        <div class="countdown" >剩余时间：<van-count-down :time="time" /></div>
        <!--       <p style="font-size:.26rem; color: #666; margin-bottom: 1.28rem; text-align:center">猪耳朵省时、省力、更省钱，聚合平台领导者</p> -->
        <div class="qrcode" id="qrcode" style="visibility: hidden"></div>
        <p style="font-size: 0.26rem; margin: .4rem 0; text-align: center">{{params.value}}</p>
      </div>
    </div>
    
  </div>
</template>
<style scoped>
  h4 { margin: .4rem 0;font-size: .6rem}
  .r-box > div { text-align: center}
  .countdown {display:flex;justify-content:center; align-items: center;font-size:.28rem}
.qrcode {
  position: relative;
  margin: 0.6rem auto;
  width: 4rem;
  height: 4rem;
  user-select: none;
}
/deep/ .qrcode img{
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  width: 100%;
  height: 100%;
  opacity:  0;
}

</style>